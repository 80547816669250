import ApiService from "@/core/services/ApiService";

// 取得IoT裝置對應
export function getDeviceMapping(deviceGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}/DeviceMapping`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增IoT裝置對應
export function addDeviceMapping(deviceGuid,request): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/DeviceMapping`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 刪除IoT裝置對應
export function deleteDeviceMapping(deviceGuid,deviceMappingGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.delete(`Device/${deviceGuid}/DeviceMapping/${deviceMappingGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得space裝置對應
export function getDeviceMappingBySpace(spaceGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/DeviceMapping`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增space裝置對應
export function addDeviceMappingBySpace(spaceGuid,request): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/DeviceMapping`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}