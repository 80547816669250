
import { defineComponent, reactive, ref } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import localstorage from "@/core/services/LocalstorageService";
import {
  getDeviceMappingBySpace,
  deleteDeviceMapping,
} from "@/core/services/api/deviceMapping";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import { getFacilityUnitByFacilities } from "@/core/services/api/facilityUnits";
import { getDevices } from "@/core/services/api/device";
import { getLocalTimeString } from "@/core/utils/timeString";
import { searchItems } from "@/core/utils/tableSearch";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const router = useRouter();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "設施",
        key: "device",
        sortable: true,
      },
      {
        name: "目標類型",
        key: "targetType",
        sortable: true,
      },
      {
        name: "目標",
        key: "target",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const targetTypeMap = {
      Facility: "設施",
      FacilityUnit: "座位",
    };
    const mappingList: any = reactive([]);
    const devices: any = reactive([]);
    const facilities: any = reactive([]);
    const facilityUnits: any = reactive([]);
    const search = ref<string>("");
    const currentIoT: any = reactive({});

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const setDevices = async () => {
      let responses = await getDevices(currentSpaceGuid);
      devices.splice(0, devices.length, ...responses);
    };

    const setMappingList = async () => {
      let responses = await getDeviceMappingBySpace(currentSpaceGuid);
      console.log("responses", responses);
      mappingList.splice(0, mappingList.length, ...responses);
    };

    const getDeviceByGuid = (guid) => {
      let device = devices.filter((o) => o.guid == guid)[0];
      return device;
    };

    const setFacilities = async () => {
      let response = await getFacilityBySpace(currentSpaceGuid!);
      console.log("response");
      facilities.splice(0, facilities.length, ...response);
    };

    const setFacilityUnits = async () => {
      let facilitiesGuid = facilities.map((o) => o.facility.guid);
      let request = { facilities: facilitiesGuid };
      let response = await getFacilityUnitByFacilities(request);
      facilityUnits.splice(0, facilityUnits.length, ...response);
    };

    const getTargetName = (targetType, guid) => {
      if (targetType == "Facility") {
        let facilityFilter = facilities.filter(
          (o) => o.facility.guid == guid
        )[0];
        if (facilityFilter == undefined) {
          return guid;
        } else {
          return facilityFilter.facility.name;
        }
      } else if (targetType == "FacilityUnit") {
        let facilityUnitFilter = facilityUnits.filter((o) => o.guid == guid)[0];
        if (facilityUnitFilter == undefined) {
          return guid;
        } else {
          return facilityUnitFilter.name;
        }
      }
    };

    const setTableData = () => {
      tableData.splice(0);
      for (const item of mappingList) {
        tableData.push({
          data: item,
          device: getDeviceByGuid(item.device).name,
          targetType: targetTypeMap[item.targetType],
          target: getTargetName(item.targetType, item.target),
          createTime: getLocalTimeString(item.createTime),
        });
      }
      initTableData.splice(0, initTableData.length, ...tableData);
      console.log("tableData", tableData);
    };

    const toAddDeviceMapping = () => {
      router.push({ path: "AddDeviceMapping" });
    };

    const setCurrentIoT = (item) => {
      Object.assign(currentIoT, item);
    };

    const deleteItem = async () => {
      await Swal.fire({
        title: "您確定要刪除嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteDeviceMapping(
            currentIoT.data.device,
            currentIoT.data.guid
          ).then(async () => {
            await Swal.fire("刪除成功!", "", "success");
            init();
          });
        }
      });
    };

    const init = async () => {
      await setFacilities();
      await setFacilityUnits();
      await setMappingList();
      await setDevices();
      setTableData();
    };
    init();

    return {
      tableData,
      initTableData,
      tableHeader,
      tableSearch,
      search,
      toAddDeviceMapping,
      setCurrentIoT,
      deleteItem,
    };
  },
});
